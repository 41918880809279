import React, { useState } from "react"
import { IconButton, Popover, Typography } from "@material-ui/core"
import styles from "./contactIcons.module.scss"
import PhoneIcon from "@material-ui/icons/Phone"
import LanguageIcon from "@material-ui/icons/Language"
import Email from "./Email"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: theme.spacing(1)
  }
}))

const ContactIcons = ({
  phone,
  email,
  website,
  hotelName = "",
  arrangementName = ""
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <div className={styles.wrapper}>
      {phone && (
        <>
          <IconButton
            aria-label="anrufen"
            href={`tel: +${phone}`}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <PhoneIcon className={styles.icon} />
          </IconButton>
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography>+{phone}</Typography>
          </Popover>
        </>
      )}
      {email && (
        <Email
          icon
          email={email}
          arrangementName={arrangementName}
          hotelName={hotelName}
          className={styles.icon}
        />
      )}
      {website && (
        <IconButton
          aria-label={`website ${website} besuchen`}
          href={website}
          target="_blank"
          alt={website}
        >
          <LanguageIcon className={styles.icon} />
        </IconButton>
      )}
    </div>
  )
}

export default ContactIcons

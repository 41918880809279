import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

const CLOUDINARY_NAME = "dsye4k3fl";
const ENV_URL = "https://www.reisezieldeutschland.de";

const CloudinaryImage = ({ url, base64, aspectRatio, sizes, ...props }) => {
  const imageSizes = [270, 540, 720, 1080, 1280, 1440, 1920];
  // add ar_1.77777,c_crop to enforce aspect ratio crop

  // ! url muss absollute url sein !!!
  const finalUrl = `${ENV_URL}${url}`;
  // console.log("xxx finalUrl ", finalUrl);

  const fluidCloudinary = {
    base64,
    aspectRatio,
    src: `https://res.cloudinary.com/${CLOUDINARY_NAME}/image/fetch/f_auto/${finalUrl}`,
    srcSet: imageSizes
      .map(
        size =>
          `https://res.cloudinary.com/${CLOUDINARY_NAME}/image/fetch/c_limit,f_auto,w_${size}/${finalUrl} ${size}w`
      )
      .join(",\n"),
    sizes,
  };
//   https://res.cloudinary.com/dsye4k3fl/image/fetch/c_limit,f_auto,w_200/https://www.reisezieldeutschland.de/static/4e9b5464736db0eaac5cbe100f12d478/7183f/hero.jpg


  return <Img fluid={fluidCloudinary} {...props} />;
};

CloudinaryImage.propTypes = {
  base64: PropTypes.string,
  url: PropTypes.string.isRequired,
  sizes: PropTypes.string.isRequired,
  aspectRatio: PropTypes.number,
};

CloudinaryImage.defaultProps = {
  base64: "",
  sizes: "(max-width: 800px) 100vw, 800px",
  aspectRatio: 1,
};

export default CloudinaryImage;

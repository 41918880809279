import React from "react"
import { IconButton } from "@material-ui/core"
import MailOutlineIcon from "@material-ui/icons/MailOutline"

const Email = ({ email, arrangementName, icon = false, className = {} }) => {
  const emailSubject = `Anfrage via ReisezielDeutschland.de${arrangementName && `: ${arrangementName}`}`;

  if (!icon)
    return <a href={`mailto:${email}?subject=${emailSubject}`}>{email}</a>

  if (icon)
    return (
      <IconButton
        aria-label="email schicken"
        href={`mailto:${email}?subject=${emailSubject}`}
      >
        <MailOutlineIcon className={className} />
      </IconButton>
    )
}

export default Email
